import { Controller } from "@hotwired/stimulus"
import { patch, post, destroy } from '@rails/request.js'

export default class extends Controller {
	static targets = ["checkboxAll", "checkbox"]
	static values = {
		weekly: String
	}
	selectedIds = null

	clicked(event) {
		if (event.target.value === "all") {
			this.checkboxTargets.forEach(checkbox => {
				checkbox.checked = true
			})
		} else {
			this.checkboxAllTarget.checked = false
		}
	}

	publishCalendar(event) {
		event.preventDefault()
		let data = new FormData()
		if (this.checkboxAllTarget.checked) {
			data.set("all", true)
		} else {
			this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
			this.selectedIds.forEach(id => data.append("ids[]", id))
		}
		const response = patch(this.publishCalendarUrl, { body: data, responseKind: "turbo-stream" })
	}

	advanceCalendar(event) {
		event.preventDefault()
		let data = new FormData()
		if (this.checkboxAllTarget.checked) {
			data.set("all", true)
		} else {
			this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
			this.selectedIds.forEach(id => data.append("ids[]", id))
		}
		const response = post(this.advanceCalendarUrl, { body: data, responseKind: "turbo-stream" })
	}

	removeCalendar(event) {
		event.preventDefault()
		let data = new FormData()
		if (this.checkboxAllTarget.checked) {
			data.set("all", true)
		} else {
			this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
			this.selectedIds.forEach(id => data.append("ids[]", id))
		}
		const response = destroy(this.removeCalendarUrl, { body: data, responseKind: "turbo-stream" })
	}

	get publishCalendarUrl() {
		return `${this.weeklyValue}/bulk_operations/publish_calendars`
	}

	get advanceCalendarUrl() {
		return `${this.weeklyValue}/bulk_operations/advance_calendars`
	}

	get removeCalendarUrl() {
		return `${this.weeklyValue}/bulk_operations/remove_calendars`
	}

}