import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import { patch } from '@rails/request.js'

export default class extends Controller {
	static targets = ["input", "message"]
	static values = { 
		updateUrl: String, 
		model: String, 
		param: String
	}

	connect() {
		if (this.hasInputTarget) {
			this.phoneInput = intlTelInput(this.inputTarget, {
    			// any initialisation options go here
    			preferredCountries: ["US", "TH", "VN", "ID", "CN", "SG", "HK", "TW", "MY", "JP", "KR"],
    			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
    			formatOnDisplay: false,
    			separateDialCode: true
			});
		}
	}

	disconnect() {
		if (this.hasInputTarget) {
			const input = this.inputTarget;
			intlTelInput(input).destroy();
		}
	}

	process(event) {
		event.preventDefault();
		if (this.phoneInput.isValidNumber()) {
			const phoneNumber = this.phoneInput.getNumber();
			this.submitForm(phoneNumber);
		} else {
			let that = this
      		that.messageTarget.innerHTML = null;
      		let division = document.createElement('div');
      		let msg = document.createTextNode(this.errorMessage);
      		division.appendChild(msg)
      		division.classList.add("input-info");
      		division.classList.add("text-danger");
			let flash = that.messageTarget.appendChild(division);
			setTimeout(() => { flash.remove(); }, 5000);
		}
	}

	async submitForm(phoneNumber) {
		let formData = new FormData();
		formData.append(this.modelValue + "[" + this.paramValue + "]", phoneNumber);
		const response = await patch(this.updateUrlValue, { body: formData })
		if (response.ok) {
			let that = this
			that.messageTarget.innerHTML = null;
      		let division = document.createElement('div');
      		let msg = document.createTextNode(this.successMessage(phoneNumber));
      		division.appendChild(msg)
      		division.classList.add("input-info");
			division.classList.add("text-success");
			let flash = that.messageTarget.appendChild(division);
			setTimeout(() => { flash.remove(); }, 5000);
		}
	}

	successMessage(phoneNumber) {
		return 'Your Mobile Phone Number is successfully updated to ' + phoneNumber + '.'
	}

	get errorMessage() {
		return 'Invalid mobile phone number!'
	}

}