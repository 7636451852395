import { Controller } from "@hotwired/stimulus"
import Trix from 'trix'
import { patch } from '@rails/request.js'

// Connects to data-controller= "trix-media-plugin"
export default class extends Controller {

    initialize() {
        this.actionId = Math.random().toString(36)
        this.dialogId = Math.random().toString(36)
        this.customId = Math.random().toString(36)
        this.inputId = Math.random().toString(36)
    }

    connect() {
        this.addTrixButton()
        this.addTrixDialog()
        this.eventListenerForMediaButton()
        this.eventListenerForAddButton()
    }

    addTrixButton() {
        const buttonHTML = '<button type="button" id="' + this.actionId + '" class="trix-button" data-trix-attribute="embed" data-trix-action="embed" data-trix-media-plugin-target="button" title="Embed" tabindex="-1">Media</button>'
        const buttonGroup = this.element.toolbarElement.querySelector(".trix-button-group--block-tools")
        buttonGroup.insertAdjacentHTML("beforeend", buttonHTML)
    }

    addTrixDialog() {
        const dialogHTML = `<div class="trix-dialog trix-dialog--link" id="` + this.dialogId + `" data-trix-dialog="embed" data-trix-dialog-attribute="embed">
                                <div class="trix-dialog__link-fields">
                                    <input type="text" id="` + this.inputId + `" name="embed" class="trix-input trix-input--dialog" placeholder="Paste your URL, such as Google Drive, YouTube or Vimeo." aria-label="embed code" required="" data-trix-input="" disabled="disabled">
                                    <div class="trix-button-group">
                                        <input type="button" class="trix-button trix-button--dialog" id="` + this.customId + `" data-trix-custom="add-embed" value="Add">
                                    </div>
                                </div>
                            </div>`
        const dialogGroup = this.element.toolbarElement.querySelector(".trix-dialogs")
        dialogGroup.insertAdjacentHTML("beforeend", dialogHTML)
    }

    eventListenerForMediaButton() {
        document.getElementById(this.actionId).addEventListener("click", event => {
            const dialog = document.getElementById(this.dialogId)
            const embedInput = document.getElementById(this.inputId)
            if (event.target.classList.contains("trix-active")) {
                event.target.classList.remove("trix-active");
                dialog.classList.remove("trix-active");
                delete dialog.dataset.trixActive;
                embedInput.setAttribute("disabled", "disabled");
            } else {
                event.target.classList.add("trix-active");
                dialog.classList.add("trix-active");
                dialog.dataset.trixActive = "";
                embedInput.removeAttribute("disabled");
                embedInput.focus();
            }
        })
    }

    eventListenerForAddButton() {
        document.getElementById(this.customId).addEventListener("click", event => {
            const content = document.getElementById(this.inputId).value
            if (content) {
                this.attachContent(content) 
            }
        })
    }

    async attachContent(content) {
        let _this = this
        let formData = new FormData()
        formData.append("content", content)
        const response = await patch('/utilities/embed', { body: formData, responseKind: "json" })
        if (response.ok) {
            const data = response.json
            data.then((result) => {
                const attachment = new Trix.Attachment(result)
                _this.element.editor.insertAttachment(attachment)
                _this.element.editor.insertLineBreak()
            })
        }
    }
}