import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
    static targets = ['canvas']
    static values = { 
        type: { type: String, default: 'line' },
        data: Object,
        options: Object,
        customColors: Boolean
    }

    connect() {
        const element = this.hasCanvasTarget ? this.canvasTarget : this.element
        const canvasContext = element.getContext("2d");

        let pinkBlue = canvasContext.createLinearGradient(140, 0, 150, 300.0);
        pinkBlue.addColorStop(0, "rgba(63,94,251,.85)");
        pinkBlue.addColorStop(1, "rgba(252,70,107, .85)");

        let chartData = this.chartData
        if (this.customColorsValue == false) {
            chartData.datasets[0]['backgroundColor'] = pinkBlue
            chartData.datasets[0]['hoverBackgroundColor'] = pinkBlue
            chartData.datasets[0]['borderColor'] = pinkBlue
        }
        
        this.chart = new Chart(canvasContext, {
            type: this.typeValue,
            data: chartData,
            options: {
                ...this.defaultOptions,
                ...this.optionsValue
            }
        })
    }

    disconnect() {
        this.chart.destroy()
        this.chart = undefined
    }

    get chartData () {
        if (this.hasDataValue) {
            return this.dataValue
        } else {
            console.warn('[stimulus-chartjs] You need to pass data as JSON to see the chart.')
        }
    }

    // You can set default options in this getter for all your charts.
    get defaultOptions () {
        return {
            legend: false,
        }
    }

}