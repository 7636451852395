import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
	static targets = ["link"]

	async clicked () {
		
		let params = new URLSearchParams()
		let priceId = this.linkTarget.getAttribute('data-value')
		params.append("price_id", priceId)
		
		const response = await post(`${this.url}?${params}`, { responseKind: "json" })
		if (response.ok) {
			const session = await response.json
		  	const stripe = await loadStripe(getMetaValue("stripe-public-key"));
			const result = await stripe.redirectToCheckout({ sessionId: session.id })
			if (result.error) { 
				console.log('failed at Stripe Redirect To Checkout')
			}
		} else {
			console.log('failed at response')
		}
	}
	
	get url() {
		return '/i/wallets/charges'
	}
}

function getMetaValue(name) {
	const element = document.head.querySelector(`meta[name="${name}"]`);
	return element.getAttribute("content");
}