import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["input", "label"]
	static values = {
		limit: Number,
		note: String
	}

	connect() {
		this.inputTarget.setAttribute("data-action", "keyup->text-limiter#keyup keypress->text-limiter#keypress")
		this.labelTarget.innerHTML = this.noteValue
	}

	keyup(event) {
		this.keypress(event)
		this.updateLabel()
	}

	keypress(event) {
		let input_length = this.inputTarget.value.toString().length
		if (input_length != undefined && input_length >= this.limitValue) {
			event.preventDefault()
			this.inputTarget.value = this.inputTarget.value.slice(0, this.limitValue)
		}

		if (input_length != undefined && input_length / this.limitValue > 0.85) {
			this.labelTarget.classList.add("text-danger")
		} else {
			this.labelTarget.classList.remove("text-danger")
		}
	}

	updateLabel() {
		let input_length = this.inputTarget.value.toString().length
		if (input_length <= 0) {
			this.labelTarget.innerHTML = this.noteValue
		} else {
			this.labelTarget.innerHTML = `${parseInt(this.limitValue) - input_length} characters remaining.`
		}
	}
}