import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--internal-chat-message"
export default class extends Controller {
    connect() {
        let chatElement = document.querySelector('.message_feed')
        let calcHeight = chatElement.scrollTop + this.element.offsetHeight + 120
        if (calcHeight / chatElement.scrollHeight > 0.50) {
            chatElement.chat.scrollToBottom()
        }
    }
}