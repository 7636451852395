import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = ["type", "detail", "description"]

	connect() {
		this.check_forms()
	}

	check_forms() {
		if (this.descriptionTarget.getAttribute('data-detail-id')) {
            this.typeTarget.value = this.descriptionTarget.getAttribute('data-type-id')
            this.detailTarget.value = this.descriptionTarget.getAttribute('data-detail-id')
            this.type_changed()
        }
	}

	async type_changed(event) {
		const response = await get(this.typeUrl, {responseKind: "json"})
		if (response.ok) {
    		const data = await response.json
            this.detailTarget.innerText = null
            this.descriptionTarget.value = null
            this.detailTarget.appendChild(document.createElement('option'))
            for (let i = 0; i < data.length; i++) {
                let opt = document.createElement('option')
                opt.textContent = data[i][0]
                opt.value = data[i][1]
                opt.setAttribute('url', data[i][2]['url'])
                this.detailTarget.appendChild(opt)
            }

            if (this.descriptionTarget.getAttribute('data-detail-id')) {
    			this.detailTarget.value = this.descriptionTarget.getAttribute('data-detail-id')
    			this.descriptionTarget.removeAttribute('data-detail-id')
                this.descriptionTarget.innerText = null
    			this.detail_changed()
    		}
        }
	}

	async detail_changed(event) {
		const response = await get(this.detailUrl, {responseKind: "json"})
		if (response.ok) {
			const data = await response.json
			this.descriptionTarget.value = null
			this.descriptionTarget.value = data.description
		}
	}

	get typeUrl() {
        return this.typeTarget.selectedOptions[0].getAttribute('url')
    }

    get detailUrl() {
    	return this.detailTarget.selectedOptions[0].getAttribute('url')
    }
}