import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="click-top"
export default class extends Controller {

    initialize() {
        this.element.setAttribute("data-action", "click->click-top#clicked");
    }

	clicked() {
		this.element[this.identifier] = this
		this.scrollToTop()
	}

	scrollToTop() {
		this.element.scrollIntoView()
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}
}