import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = { uploading: Boolean, message: String }

	startDirectUploads(event) {
		this.uploadingValue = true;
	}

	endDirectUploads(event) {
		this.uploadingValue = false;
	}

	leavingPage(event) {
		if (this.uploadingValue == true) {
			if (event.type == "turbo:before-visit") {
				if (!window.confirm(this.messageValue)) {
					event.preventDefault();
				}
			} else {
				event.returnValue = this.messageValue;
				return event.returnValue;
			}
		}
	}
}