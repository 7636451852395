import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from '@rails/activestorage'
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = ["input", "image", "toggle"]
    static values = {
        layout: String
    }

    connect() {
        this.inputTarget.setAttribute("data-action", "instant-upload#changed")
    }

  	changed() {
    	Array.from(this.inputTarget.files).forEach(file => {
      		const upload = new DirectUpload(file, this.postURL())
      		upload.create((error, blob) => {
        		if (error) {
					// Do something for the error...
				} else {
					this.hiddenInput().value = blob.signed_id
        			this.serviceURL(this.getURL(blob.id))
				}
      		})
    	})
  	}

  	hiddenInput() {
    	if (this._hiddenInput == undefined ) {
    		this._hiddenInput = document.createElement('input')
    		this._hiddenInput.name = this.inputTarget.name
    		this._hiddenInput.type = "hidden"
    		this.inputTarget.parentNode.insertBefore(this._hiddenInput, this.inputTarget.nextSibling)
		}
		return this._hiddenInput
  	}

  	// Bind an action on this method
    async serviceURL (url) {
        const response = await get(url, { responseKind: "json" })
        if (response.ok) {
            const data = await response.text
            this.imageTarget.src = data
            if (this.imageTarget.dataset.uploadToggle == 'true') {
                this.toggleTargets.forEach((el) => {
                    el.classList.remove('hidden-div')
                })
            }
        }
    }

  	postURL() {
    	return this.inputTarget.dataset.directUploadUrl
  	}

	getURL(blob) {
    	return 'http://localhost:3000/' + this.layoutValue + '/active_storage/blobs/' + blob
	}
}