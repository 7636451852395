import { Controller } from "@hotwired/stimulus"
import { destroy } from '@rails/request.js'

export default class extends Controller {
	static targets = ["element"]
	static values = { url: String }

	async clicked() {
  		const response = await destroy(this.urlValue, {})
  		if (response.ok) {
  			fadeOut(this.elementTarget)
  		}
	}

}

// https://dev.to/bmsvieira/vanilla-js-fadein-out-2a6o
function fadeOut(element) {
	element.style.opacity = 1;
	(function fade() {
		if ((element.style.opacity -= .1) < 0) {
			element.style.display = "none";
		} else {
			requestAnimationFrame(fade);
		}
	})();
};