import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="enquiry-display"
export default class extends Controller {
    static values = { 
        author: String
    }
    
    initialize() {
        this.chatId = Math.random().toString(36)
        this.element.setAttribute("id", this.chatId)
    }

    connect() {
        this.sortPosition()
    }

    sortPosition() {
        if (this.authorValue != "Staff") {
            const el = document.getElementById(this.chatId);
            el.classList.add('flex-row-reverse')
        }
    }
}