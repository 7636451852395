import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {

	connect() {
		this.sortable = Sortable.create(this.element, {
			animation: 150,
			onEnd: this.updatePosition.bind(this)
		})
	}

	async updatePosition(event) {
		const response = await patch(this.updateUrl, {
			body: JSON.stringify({
				sgid: event.item.dataset.sgid,
				position: event.newIndex + 1
			})
		})
		if (response.ok) {
			console.log('Success!')
		}
	}

	get updateUrl() {
		return '/admin/utilities/sortables'
	}
}