import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
	static targets = ["link"]

	async clicked () {
		const response = await post(this.url, { responseKind: "json" })
  		if (response.ok) {
  			const data = await response.json
  			window.location.replace(data.url)
  		}
	}

	get url () {
		return 'http://localhost:3000/i/wallets/billing_portals'
	}
}