import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["toast", "dot"]

	connect() {
        if (!this.hasToastTarget) return        
        import("bootstrap").catch(err => null)
        this.toast = new bootstrap.Toast(this.toastTarget, {
            animation: true,
            autohide: true, 
            delay: 5000
        })
        this.dotTarget.classList.add(flashTypeClass(this.flashType));
        this.toast.show();
    }

    disconnect() {
        this.toast.hide();
    }

	get flashType() {
        return this.toastTarget.getAttribute('data-flash-type')
    }
}

function flashTypeClass(type) {
    if (!type) return "bg-info"
    switch (type) 
            {
                case 'success': 
                    return "bg-success"
                    break;
                case 'error': 
                    return "bg-danger"
                    break;
                case 'alert': 
                    return "bg-warning"
                    break;
                case 'notice': 
                    return "bg-info"
                    break;
            }
}