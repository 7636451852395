import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from '@rails/activestorage'

// Connects to data-controller= "trix-attachments"
export default class extends Controller {
    static targets = ["input"]
    static values = {
        fieldName: String
    }

    initialize() {
        this.trix = document.querySelector('trix-editor')
    }

    connect() {
        this.eventListerForFileAccept()
    }

    eventListerForFileAccept() {
        document.addEventListener("trix-file-accept", event => {
            const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
            const maxFileSize = 5120 * 5120 // 5MB 
            if (!acceptedTypes.includes(event.file.type) || event.file.size > maxFileSize) {
                event.preventDefault()
                alert("Only support attachment of jpeg, jpg, png or pdf files, and a file size must be less than 5MB.")
            } else {
                this.uploadFile(event.file)
            }
        })
    }

    uploadFile(file) {
        const url = this.trix.dataset.directUploadUrl
        const uploader = new Uploader()
        const upload = new DirectUpload(file, url, uploader)
        upload.create((error, blob) => {
            if (error) {
                alert("Sorry, something went wrong with file attachment. Please try again.")
            } else {
              // Add an appropriately-named hidden input to the form with a
              //  value of blob.signed_id so that the blob ids will be
              //  transmitted in the normal upload flow
              this.hiddenInput().value = blob.signed_id
            }
        })
        uploader.directUploadWillStoreFileWithXHR(request)
    }

    hiddenInput() {
    	if (this._hiddenInput == undefined ) {
    		this._hiddenInput = document.createElement('input')
    		this._hiddenInput.name = this.fieldNameValue
    		this._hiddenInput.type = "hidden"
    		this.inputTarget.parentNode.insertBefore(this._hiddenInput, this.inputTarget.nextSibling)
		}
		return this._hiddenInput
  	}

}

class Uploader {
    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress", event => this.directUploadDidProgress(event))
    }

    directUploadDidProgress(event) {
        let progress = ((event.loaded / event.total) * 100).toFixed(1)
        const attachments = document.querySelector('trix-editor').editor.composition.attachments
        Array.from(attachments).forEach(file => file.setUploadProgress(progress))
    }
}