import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

const RESET_TIMEOUT_MILLIS = 3000;

export default class extends Controller {
	static targets = ["button"]
	static values = { 
		url: String
	}

	connect() {
		this.job = false;
	}

	async clicked(e) {
		e.preventDefault();
		this.buttonTarget.disabled = true
		const response = await get(this.urlValue, { })
		if (response.ok) {
			this.oldMessage = this.buttonTarget.innerHTML;
			this.buttonTarget.innerHTML = this.successMessage;
			this.job = true;
			this.timeout = setTimeout(() => { this.resetState(); }, RESET_TIMEOUT_MILLIS);
			return false
		} else {
			console.log('failed to process the job.')
		}
	}

	resetState() {
		if (this.job) {
			this.buttonTarget.innerHTML = this.oldMessage;
			this.buttonTarget.disabled = false
			this.job = false;
		}
	}

	get successMessage() {
		return 'Start Processing!'
	}
}