import { Controller } from "@hotwired/stimulus"
import { Offcanvas } from "bootstrap"

// Connects to data-controller= "offcanvas"
export default class extends Controller {
	
	connect() {
		this.offcanvas = new Offcanvas(this.element, {
			keyword: true,
			backdrop: true,
			scroll: false
		})
		this.offcanvas.show()
	}

	disconnect() {
		this.offcanvas.hide();
	}

	close() {
		this.offcanvas.toggle();
	}
}