import { Controller } from "@hotwired/stimulus"
import { DateRangePicker } from 'vanillajs-datepicker';

export default class extends Controller {
	static targets = ["input"]

  	dateClicked() {
  		const element = this.inputTarget
		this.datepicker = new DateRangePicker(element, this.defaultOptions)
  	}

	get defaultOptions () {
    	return {
    		// options...
        	buttonClass: 'btn',
			clearBtn: true,
			format: 'yyyy-mm-dd'
    	}
  	}

}