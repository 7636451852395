import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

export default class extends Controller {
	static targets = ["input"]
	static values = { updateUrl: String, model: String, param: String }

	connect() {
        if (this.inputTarget.id === "") {
            this.inputTarget.id = Math.random().toString(36)
        }
    }

	toggle(event) {
		let formData = new FormData();
		let params = new URLSearchParams()
		formData.append(this.modelValue + "[" + this.paramValue + "]", this.inputTarget.checked)
		params.append("target", this.inputTarget.id)
		
		patch(`${this.updateUrlValue}?${params}`, { 
			body: formData, 
			responseKind: "turbo-stream" 
		})
	}

}