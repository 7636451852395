import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["input", "display"]
	static values = {
		text: String
	}

	connect() {
		this.inputTarget.setAttribute("data-action", "input->text-displayer#keyup")
	}

	keyup(event) {
		this.updateDisplay();
	}

	updateDisplay() {
		let input_length = this.inputTarget.value.toString().length
		if (input_length <= 0) {
			this.displayTarget.innerHTML = this.textValue
		} else {
			this.displayTarget.innerHTML = this.inputTarget.value
		}
	}
}