import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["sales", "fee", "amount"]
	static values = {
		cost: Number,
		depreciation: Number
	}

	connect() {
		this.salesTarget.setAttribute("data-action", "input->admin--asset-disposal#calculate");
		this.feeTarget.setAttribute("data-action", "input->admin--asset-disposal#calculate");
		this.updateAmount();
	}

	calculate(event) {
		let sales_process = parseFloat(this.salesTarget.value)
		let disposal_fee = parseFloat(this.feeTarget.value)
		let gain_or_loss = sales_process - disposal_fee - (parseFloat(this.costValue) - parseFloat(this.depreciationValue))
		if (isNaN(gain_or_loss)) {
			this.updateAmount()
		} else {
			let amount = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(gain_or_loss)
			this.amountTarget.innerText = "SG$ " + amount.toString()
		}
	}

	updateAmount() {
		let gain_or_loss = 0 - (parseFloat(this.costValue) - parseFloat(this.depreciationValue))
		let amount = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(gain_or_loss)
		this.amountTarget.innerText = "SG$ " + amount.toString()
	}
}