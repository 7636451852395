import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = ["select", "price", "priceButton", "selectPrice", "basic", "silver", "gold", "platinum", "credit"];
	static classes = ['primary'];
	static values = { key: String };

	initialize () {
    	this.toggle = this.toggle.bind(this)
	}

 	connect () {
		if (!this.hasSelectTarget) return
		this.memberType = this.keyValue
		this.selectTarget.addEventListener('change', this.toggle)
	}

	disconnect () {
		if (!this.hasSelectTarget) return
		this.selectTarget.removeEventListener('change', this.toggle)
	}

	toggle (e) {
		let value = e.target.value;
		this.url = e.target.selectedOptions[0].getAttribute('url')
		this.creditTarget.disabled = true;
		this.creditTarget.classList.remove(this.primaryClass);
      	this.creditTarget.textContent = 'PROCEED TO CHECKOUT';
      	this.setCurrency(value);
	}

	setCurrency(value) {
		switch (value) {
			case 'thb':
				// thb price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "฿ 2,700 THB"
							break;
						case 'silver':
							price.textContent = "฿ 3,850 THB"
							break;
						case 'gold':
							price.textContent = "฿ 5,400 THB"
							break;
						case 'platinum':
							price.textContent = "฿ 70,000 THB"
							break;
						default:
					} 
				});
				// thb price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "฿ 2,700 THB/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "฿ 3,850 THB/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "฿ 5,400 THB/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "฿ 70,000 THB/ month"
							}
							break;
						default:
					}
				});
				// Update select box to THB
				this.update() 
				// Update membership price ids (THB)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 1);
					this.silverTarget.setAttribute("data-value", 2);
					this.goldTarget.setAttribute("data-value", 3);
					this.platinumTarget.setAttribute("data-value", 4);
				} 
				break;
			case 'jpy':
				// jpy price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "¥ 11,340 JPY"
							break;
						case 'silver':
							price.textContent = "¥ 16,170 JPY"
							break;
						case 'gold':
							price.textContent = "¥ 22,680 JPY"
							break;
						case 'platinum':
							price.textContent = "¥ 294,000 JPY"
							break;
						default:
					} 
				});
				// jpy price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "¥ 11,340 JPY/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "¥ 16,170 JPY/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "¥ 22,680 JPY/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "¥ 294,000 JPY/ month"
							}
							break;
						default:
					}
				});
				// Update select box to JPY
				this.update()
				// Update membership price ids (JPY)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 26);
					this.silverTarget.setAttribute("data-value", 27);
					this.goldTarget.setAttribute("data-value", 28);
					this.platinumTarget.setAttribute("data-value", 29);
				} 
				break;
			case 'krw':
				// krw price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "₩ 102,600 KRW"
							break;
						case 'silver':
							price.textContent = "₩ 146,300 KRW"
							break;
						case 'gold':
							price.textContent = "₩ 205,200 KRW"
							break;
						case 'platinum':
							price.textContent = "₩ 2,660,000 KRW"
							break;
						default:
					} 
				});
				// krw price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "₩ 102,600 KRW/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "₩ 146,300 KRW/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "₩ 205,200 KRW/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "₩ 2,660,000 KRW/ month"
							}
							break;
						default:
					}
				});
				// Update select box to KRW
				this.update() 
				// Update membership price ids (KRW)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 51);
					this.silverTarget.setAttribute("data-value", 52);
					this.goldTarget.setAttribute("data-value", 53);
					this.platinumTarget.setAttribute("data-value", 54);
				} 
				break;
			case 'sgd':
				// sgd price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "S$ 102.60 SGD"
							break;
						case 'silver':
							price.textContent = "S$ 146.30 SGD"
							break;
						case 'gold':
							price.textContent = "S$ 205.20 SGD"
							break;
						case 'platinum':
							price.textContent = "S$ 2,600.00 SGD"
							break;
						default:
					} 
				});
				// sgd price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "S$ 102.60 SGD/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "S$ 146.30 SGD/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "S$ 205.20 SGD/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "S$ 2,600.00 SGD/ month"
							}
							break;
						default:
					}
				});
				// Update select box to SGD
				this.update() 
				// Update membership price ids (SGD)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 76);
					this.silverTarget.setAttribute("data-value", 77);
					this.goldTarget.setAttribute("data-value", 78);
					this.platinumTarget.setAttribute("data-value", 79);
				} 
				break;
			case 'hkd':
				// hkd price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "HK$ 607.50 HKD"
							break;
						case 'silver':
							price.textContent = "HK$ 866.20 HKD"
							break;
						case 'gold':
							price.textContent = "HK$ 1,215.00 HKD"
							break;
						case 'platinum':
							price.textContent = "HK$ 15,750.00 HKD"
							break;
						default:
					} 
				});
				// hkd price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "HK$ 607.50 HKD/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "HK$ 866.20 HKD/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "HK$ 1,215.00 HKD/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "HK$ 15,750.00 HKD/ month"
							}
							break;
						default:
					}
				});
				// Update select box to HKD
				this.update() 
				// Update membership price ids (HKD)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 101);
					this.silverTarget.setAttribute("data-value", 102);
					this.goldTarget.setAttribute("data-value", 103);
					this.platinumTarget.setAttribute("data-value", 104);
				} 
				break;
			case 'idr':
				// idr price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "Rp 1,209,600 IDR"
							break;
						case 'silver':
							price.textContent = "Rp 1,724,800 IDR"
							break;
						case 'gold':
							price.textContent = "Rp 2,419,200 IDR"
							break;
						case 'platinum':
							price.textContent = "Rp 31,360,000 IDR"
							break;
						default:
					} 
				});
				// idr price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "Rp 1,209,600 IDR/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "Rp 1,724,800 IDR/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "Rp 2,419,200 IDR/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "Rp 31,360,000 IDR/ month"
							}
							break;
						default:
					}
				});
				// Update select box to IDR
				this.update() 
				// Update membership price ids (IDR)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 126);
					this.silverTarget.setAttribute("data-value", 127);
					this.goldTarget.setAttribute("data-value", 128);
					this.platinumTarget.setAttribute("data-value", 129);
				} 
				break;
			case 'myr':
				// myr price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "RM 364.50 MYR"
							break;
						case 'silver':
							price.textContent = "RM 519.75 MYR"
							break;
						case 'gold':
							price.textContent = "RM 729.00 MYR"
							break;
						case 'platinum':
							price.textContent = "RM 9,450.00 MYR"
							break;
						default:
					} 
				});
				// myr price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "RM 364.50 MYR/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "RM 519.75 MYR/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "RM 729.00 MYR/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "RM 9,450.00 MYR/ month"
							}
							break;
						default:
					}
				});
				// Update select box to MYR
				this.update() 
				// Update membership price ids (MYR)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 151);
					this.silverTarget.setAttribute("data-value", 152);
					this.goldTarget.setAttribute("data-value", 153);
					this.platinumTarget.setAttribute("data-value", 154);
				} 
				break;
			case 'twd':
				// twd price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "NT$ 2,403 TWD"
							break;
						case 'silver':
							price.textContent = "NT$ 3,427 TWD"
							break;
						case 'gold':
							price.textContent = "NT$ 4,806 TWD"
							break;
						case 'platinum':
							price.textContent = "NT$ 62,300 TWD"
							break;
						default:
					} 
				});
				// twd price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "NT$ 2,403 TWD/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "NT$ 3,427 TWD/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "NT$ 4,806 TWD/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "NT$ 62,300 TWD/ month"
							}
							break;
						default:
					}
				});
				// Update select box to TWD
				this.update() 
				// Update membership price ids (TWD)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 176);
					this.silverTarget.setAttribute("data-value", 177);
					this.goldTarget.setAttribute("data-value", 178);
					this.platinumTarget.setAttribute("data-value", 179);
				} 
				break;
			case 'usd':
				// usd price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
					case 'basic':
						price.textContent = "US$ 76.95 USD"
						break;
					case 'silver':
						price.textContent = "US$ 109.70 USD"
						break;
					case 'gold':
						price.textContent = "US$ 153.90 USD"
						break;
					case 'platinum':
						price.textContent = "US$ 1,995.00 USD"
						break;
					default:
					} 
				});
				// usd price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
					case 'basic':
						if (this.memberType == "basic") {
							priceButton.textContent = "Upgrade Membership"
						} else {
							priceButton.innerHTML = "US$ 76.95 USD/ month"
						}
						break;
					case 'silver':
						if (this.memberType == "silver") {
							priceButton.textContent = "Upgrade Membership"
						} else {
							priceButton.innerHTML = "US$ 109.70 USD/ month"
						}
						break;
					case 'gold':
						if (this.memberType == "gold") {
							priceButton.textContent = "Upgrade Membership"
						} else {
							priceButton.innerHTML = "US$ 153.90 USD/ month"
						}
						break;
					case 'platinum':
						if (this.memberType == "platinum") {
							priceButton.textContent = "You're awesome!!"
						} else {
							priceButton.innerHTML = "US$ 1,995.00 USD/ month"
						}
						break;
					default:
					}
				});
				// Update select box to USD
				this.update() 
				// Update membership price ids (USD)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 201);
					this.silverTarget.setAttribute("data-value", 202);
					this.goldTarget.setAttribute("data-value", 203);
					this.platinumTarget.setAttribute("data-value", 204);
				} 
				break;
			case 'vnd':
				// vnd price
				this.priceTargets.forEach(price => {
					switch (price.getAttribute('data-membership')) {
						case 'basic':
							price.textContent = "₫ 1,890,000 VND"
							break;
						case 'silver':
							price.textContent = "₫ 2,695,000 VND"
							break;
						case 'gold':
							price.textContent = "₫ 3,780,000 VND"
							break;
						case 'platinum':
							price.textContent = "₫ 49,000,000 VND"
							break;
						default:
					} 
				});
				// vnd price button
				this.priceButtonTargets.forEach(priceButton => {
					switch (priceButton.getAttribute('data-membership')) {
						case 'basic':
							if (this.memberType == "basic") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "₫ 1,890,000 VND/ month"
							}
							break;
						case 'silver':
							if (this.memberType == "silver") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "₫ 2,695,000 VND/ month"
							}
							break;
						case 'gold':
							if (this.memberType == "gold") {
								priceButton.textContent = "Upgrade Membership"
							} else {
								priceButton.innerHTML = "₫ 3,780,000 VND/ month"
							}
							break;
						case 'platinum':
							if (this.memberType == "platinum") {
								priceButton.textContent = "You're awesome!!"
							} else {
								priceButton.innerHTML = "₫ 49,000,000 VND/ month"
							}
							break;
						default:
					}
				});
				// Update select box to vnd
				this.update()
				// Update membership price ids (vnd)
				if (this.memberType == "non_member") {
					this.basicTarget.setAttribute("data-value", 226);
					this.silverTarget.setAttribute("data-value", 227);
					this.goldTarget.setAttribute("data-value", 228);
					this.platinumTarget.setAttribute("data-value", 229);
				}
				break;
			default:
		}
	}

	async update () {
		const response = await get(this.url, { responseKind: "json" })
		if (response.ok) {
			const data = await response.json
        	var that = this
        	that.selectPriceTarget.innerText = null;
        	that.selectPriceTarget.appendChild(document.createElement('option'))
        	for(let i = 0; i < data.length; i++) {
        		var opt = document.createElement('option')
        		opt.textContent = data[i]['metadata']['credits'] + ' Credits - ' + data[i]['metadata']['display_name']
        		opt.value = data[i]['id']
        		that.selectPriceTarget.appendChild(opt)
        	}
		}
    }

}