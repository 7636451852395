import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapse"
export default class extends Controller {
	static targets = ["button", "toggle"]

	initialize() {
		this.buttonTarget.setAttribute("data-action", "click->collapse#clicked");
	}

	connect() {
		let element = this.buttonTarget
		this.toggleTarget.addEventListener('show.bs.collapse', function() {
			element.setAttribute("aria-expanded", true);
		});
		this.toggleTarget.addEventListener('hide.bs.collapse', function() {
			element.setAttribute("aria-expanded", false);
		});
	}

	clicked() {
		this.collapse = new bootstrap.Collapse(this.toggleTarget, {})
		this.collapse.toggle()
	}
}