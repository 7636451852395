import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
	static targets = ["select"]

	connect() {
		if (!this.hasSelectTarget) return 
		this.choices = new Choices(this.selectTarget, this.defaultOptions)
	}

	disconnect() {
		this.choices.destroy();
	}

	get defaultOptions() {
		return {
			placeholder: true, 
			searchPlaceholderValue: "Search",
			itemSelectText: "Select",
			allowHTML: true,
			removeItemButton: true
		}
	}

}