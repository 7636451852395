import { Controller } from "@hotwired/stimulus";
import validate from 'validate.js';

export default class extends Controller {
    static targets = ['form', 'submit', 'input']

    initialize() {
        this.submitTarget.setAttribute("data-action", "click->validate#submitForm");
    }

    submitForm(event) {
        event.preventDefault()

        var errors = validate(this.formTarget, this.constraints())

        if (errors == null){
            this.formTarget.submit()
        } else {
            this.showErrors(errors || {})
        }
    }

    showErrors(errors) {
        for (let input of this.inputTargets) {
            this.showErrorsForInput(input, errors[input.name])
        }
    }

    constraints(){
        var constraints = {}
        for (let input of this.inputTargets) {
            constraints[input.name] = JSON.parse(input.getAttribute('data-validate'))
        }
        return constraints
    }

    showErrorsForInput(input, errors) {
        this.clearErrors(input)
        //let border = input.getAttribute('data-form-type') == "boxed" ? 'error-border' : 'error-border-bottom'
        if (errors) {
            input.classList.add('has-error')
            //input.classList.add(border)
            input.labels[0].classList.add('error-label')
            this.insertErrorMessages(input, errors)
        } else {
            input.classList.remove('has-error')
            //input.classList.remove(border)
            input.labels[0].classList.remove('error-label')
            input.parentElement.classList.add('has-success')
        }
    }

    clearErrors(input) {
        if (document.getElementById(`error_${input.name}`) != null) {
            document.getElementById(`error_${input.name}`).remove()
        }
    }

    insertErrorMessages(input, errors) {
        var html = document.createElement('div')
        html.innerHTML = errors.join(' ')
        html.id = `error_${input.name}`
        html.classList.add("error-message")
        input.after(html)
    }

}