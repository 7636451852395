import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-display"
export default class extends Controller {
    static values = { 
        owner: Number
    }
    
    initialize() {
        this.chatId = Math.random().toString(36)
        this.userId = getCurrentUserId()
        this.element.setAttribute("id", this.chatId)
    }

    connect() {
        this.sortPosition()
    }

    sortPosition() {
        if (this.userId != this.ownerValue) {
            const el = document.getElementById(this.chatId);
            el.classList.add('flex-row-reverse')
        }
    }
}

function getCurrentUserId() {
    const element = document.head.querySelector(`meta[name="current-user"]`);
    return element.getAttribute("content");
}