import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import { Calendar } from "@fullcalendar/core"
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from "@fullcalendar/list"
import bootstrap5Plugin from '@fullcalendar/bootstrap5'

// Connects to data-controller="calendar"
export default class extends Controller {
    static values = {
        timeZone: { type: String, default: 'local' },
        eventUrl: String
    }
    
    connect() {
        this.initializeCalendar()
    }

    disconnect() {
        this.calendar.destroy
    }

    initializeCalendar() {
        let _this = this
        this.calendar = new Calendar(this.element, {
            plugins: [momentTimezonePlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin],
            timeZone: this.timeZoneValue,
            firstDay: 1,
            events: this.eventUrlValue,
            slotDuration: "01:00:00",
            allDaySlot: false,
            editable: false,
            eventDurationEditable: false,
            navLinks: true,
            nowIndicator: true,
            themeSystem: 'bootstrap5',
            initialView: 'dayGridMonth',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
            },
            buttonIcons: {
                prev: 'chevron-compact-left',
                next: 'chevron-compact-right',
                dayGridMonth: 'calendar-month',
                timeGridWeek: 'calendar-week',
                timeGridDay: 'calendar-date',
                listMonth: 'list-task'
            },
            eventClick: function (info) {
                info.jsEvent.preventDefault()
                get(info.event.extendedProps.show_url, {
                    responseKind: "turbo-stream"
                })
            }
        })
        this.calendar.render()
    }
}