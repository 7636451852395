import { Controller } from "@hotwired/stimulus"
import { post, patch, destroy } from '@rails/request.js'

// Connects to data-controller="bulk-ops"
// This is good for a page where there is only one bulk operation
export default class extends Controller {
	static targets = ["checkboxAll", "checkbox"]
	static values = {
		url: String,
		method: { type: String, default: 'post' },
		confirmation: { type: Boolean, default: false },
		message: { type: String, default: 'Are you sure for proceeding this operation?' }
	}
	selectedIds = null

	clicked(event) {
		if (event.target.value === "all") {
			this.checkboxTargets.forEach(checkbox => {
				checkbox.checked = true
			})
		} else {
			this.checkboxAllTarget.checked = false
		}
	}

	processBulk(event) {
		let confirmed = this.confirmationValue ? confirm(this.messageValue) : true
        
        if (!confirmed) {
            event.preventDefault()
        } else {
            event.preventDefault()

			let data = new FormData()
			if (this.checkboxAllTarget.checked) {
				data.set("all", true)
			} else {
				this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
				this.selectedIds.forEach(id => data.append("ids[]", id))
			}
		
			if (this.methodValue == 'post') {
				const response = post(this.urlValue, { body: data, responseKind: "turbo-stream" });
			} else if (this.methodValue == 'patch') {
				const response = patch(this.urlValue, { body: data, responseKind: "turbo-stream" });
			} else if (this.methodValue == 'destroy') {
				const response = destroy(this.urlValue, { body: data, responseKind: "turbo-stream" });
			}
        }
	}
}