import { Controller } from "@hotwired/stimulus"

// Connects to data-controller= "trix-disable-attachment"
export default class extends Controller {
    initialize() {
		document.addEventListener("trix-initialize", function(e) {
            const file_tools = document.querySelector(".trix-button-group--file-tools");
            file_tools.remove();
        });
        document.addEventListener("trix-file-accept", function(e) {
            e.preventDefault();
        });
	}
}