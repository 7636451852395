import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["list", "amount", "total", "input"]

	initialize() {
		this.totalTarget.innerText = "0.00"
		this.inputTarget.value = 0.00
	}

	capture(e) {
		e.preventDefault();
		if (e.target.value > 0 ) {
			const arr = new Array();
			this.listTargets.forEach((el, i) => {
				if (el.hidden == false) {
					arr.push(parseFloat(this.amountTargets[i].value))
				}
			})
			let total = arr.reduce((a, b) => a + b, 0)
			this.inputTarget.value = total
			this.totalTarget.innerText = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(total)
		}
	}

	remove() {
		const arr = new Array();
		this.listTargets.forEach((el, i) => {
			if (el.hidden == false) {
				arr.push(parseFloat(this.amountTargets[i].value))
			}
		})
		let total = arr.reduce((a, b) => a + b, 0)
		this.inputTarget.value = total
		if (total == 0) {
			this.totalTarget.innerText = "0.00"
		} else {
			this.totalTarget.innerText = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(total)
		}
	}
}