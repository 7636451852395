import { Controller } from "@hotwired/stimulus"
import AgoraRTC from "agora-rtc-sdk-ng"
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import { AIDenoiserExtension } from "agora-extension-ai-denoiser";
import { get, post } from '@rails/request.js'

// Connects to data-controller="agora"
export default class extends Controller {
    static targets = ["join", "leave", "micOff", "micOn", "cameraOff", "cameraOn"]
    static values = {
        channel: String,
        uid: Number,
        url: { type: String, default: 'empty' },
        backgroundColor: { type: String, default: '#30D5C8' },
        members: Object // { 565677=>"student", 123685=>"teacher"}
    }

    initialize() {        
        this.role = this.membersValue[this.uidValue];
        this.config = {
            // Pass your App ID here.
            appId: '6280196fddc643d48074c80109d50f17',
            // Set the channel name.
            channel: this.channelValue,
            // Pass your temp token here.
            token: null,
            // Set the user ID.
            uid: Number(this.uidValue),
            // expiration time in seconds (default: 3600) which is 60 minutes
            // expire: 3600
        };
        // fetch authentication tokens
        this.config.token = this.fetchToken();
        this.localTracks = {
            // A variable to hold a local audio track.
            audioTrack: null,
            // A variable to hold a local video track.
            videoTrack: null,
            // Set variable to hold screen tracks
            screenVideoTrack: null,
            screenAudioTrack: null,
            // Set ID on the reserved video player
            playerId: [this.role, this.uidValue].join("_"),
        }
        this.remoteUsers = {};
        this.backgroundProcessor = null;
        this.backgroundColor = this.backgroundColorValue;
        this.denoiserProcessor = null;
        this.isDenoiserEnabled = false;
        this.isVirtualBackgroundEnabled = false;
        this.isSharingEnabled = false;
        this.isMuteVideo = false;
        this.isMuteAudio = false;
        // Create an instance of the Agora Engine
        this.agoraInit();
        // Create a VirtualBackgroundExtension instance
        this.virtualBackgroundInit();
        // Create an AIDenoiserExtension instance
        this.aiDenoiserInit();
    }

    get serverURL() {
        return 'https://agora-token-service-hv0g.onrender.com/rtc/' + this.channelValue + '/' + this.membersValue[this.uidValue] + '/uid/' + Number(this.uidValue)
    }

    async fetchToken() {
        const response = await post(this.serverURL, { responseKind: "json" })
        if (response.ok) {
            console.log("Video SDK token fetched from the server");
            return response.rtcToken
        }
    }

    agoraInit() {
        const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp9" });
        this.agoraEngine = agoraEngine;
    }

    virtualBackgroundInit() {
        const extension = new VirtualBackgroundExtension();
        // Check browser compatibility virtual background extension
        if (!extension.checkCompatibility()) {
            console.error("Does not support Virtual Background!");
            // Handle exit code
        }
        // Register the extension
        AgoraRTC.registerExtensions([extension]);
        this.virtualBackground = extension;
    }

    aiDenoiserInit() {
        // Create AIDenoiserExtension instance, assetsPath is the path of wasm files.
        const extension = new AIDenoiserExtension({assetsPath: ''});
        // Check compatibility
        if (!extension.checkCompatibility()) {
            console.error("Does not support AI Denoiser!");
            // Handle exit code
        }
        // Register the extension
        AgoraRTC.registerExtensions([extension]);
        this.denoiser = extension;
    }

    connect() {
        // Event Listeners
        // Listen to local user switching media input devices
        this.notifyMicChange();
        this.notifyCameraChange();
        // Listen to the "user-published" event.
        this.agoraEngine.on("user-published", (user, mediaType) => {this.subscribe(user, mediaType)});
        // Listen for the "user-unpublished" event.
        this.agoraEngine.on("user-unpublished", (user) => {this.handleVSDKEvents("user-unpublished", user)});
        // Set Remote Users
        this.setRemoteUsers();
    }

    async setRemoteUsers() {
        const users = await this.agoraEngine.remoteUsers;
        if (!isObjectEmpty(users)) {
            users.forEach((user) => {
                this.remoteUsers[user.uid] = user;
                this.remoteUsers[user.uid].playerId = [this.membersValue[user.uid], user.uid].join("_");
                let mediaType = user.hasVideo ? "video" : "audio"
                this.subscribe(user, mediaType)
            })
        }
    }

    async subscribe(user, mediaType) {
        // Subscribe to the remote user when the SDK triggers the "user-published" event.
        await this.agoraEngine.subscribe(user, mediaType);
        this.handleVSDKEvents("user-published", user, mediaType);
    }
    
    // The eventsCallback callback to handle all events.
    handleVSDKEvents(eventName, ...args) {
        switch (eventName) {
        case "user-published":
            // Add to remote users list
            this.remoteUsers[args[0].uid] = args[0];
            this.remoteUsers[args[0].uid].playerId = [this.membersValue[args[0].uid], args[0].uid].join("_");
            this.handleUserPublished(args[0], args[1]);
        case "user-unpublished":
            delete this.remoteUsers[args[0].uid];
            console.log(args[0].uid + " has left the channel")
        }
    }

    async handleUserPublished(user, mediaType) {
        // Set remote channel
        let remoteTrack = {
            videoTrack: null,
            audioTrack: null,
        }
        // Retrieve the remote video track.
        remoteTrack.videoTrack = user.videoTrack;
        // Retrieve the remote audio track.
        remoteTrack.audioTrack = user.audioTrack;
        // Set remote video channel
        if (mediaType == "video") {
            const remotePlayerContainer = document.getElementById(this.remoteUsers[user.uid].playerId);
            // Play the remote video track.
            remoteTrack.videoTrack.play(remotePlayerContainer);
        }
        // Set remote audio channel
        if (mediaType == "audio") {
            remoteTrack.audioTrack.play();
        }      
    }

    

    async startCall() {
        // Join a channel.
        await this.agoraEngine.join(this.config.appId, this.config.channel, this.config.token, this.config.uid);
        // Create a local audio track from the audio sampled by a microphone.
        this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({encoderConfig: setSoundProfile(this.role)});
        // Create a local video track from the video captured by a camera.
        this.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({encoderConfig: setVideoProfile(this.role)});
        // Publish the local audio and video tracks in the channel.
        await this.agoraEngine.publish([this.localTracks.audioTrack, this.localTracks.videoTrack]);
        // Set Local Player Container
        const localPlayerContainer = document.getElementById(this.localTracks.playerId);
        // Play the local video track.
        this.localTracks.videoTrack.play(localPlayerContainer);
        // Toggles Join button
        if (this.role == "teacher") {
            document.getElementById('castScreen').style.display = 'none';
        }
        this.joinTarget.hidden = true;
        this.leaveTarget.hidden = false;
        // Set a solid color as the background
        this.setBackgroundColor();
        // Set AI Noise Suppression
        this.connectDenoiser();
    }

    async getBackgroundProcessorInstance() {
        // Create a VirtualBackgroundProcessor instance
        this.backgroundProcessor = this.virtualBackground.createProcessor();
        try {
            // Initialize the extension and pass in the URL of the Wasm file
            await this.backgroundProcessor.init("./assets/wasms");
        } catch(e) {
            console.log("Fail to load Virtual Background WASM resource!");
            return null;
        }
        // Inject the extension into the video processing pipeline in the SDK
        this.localTracks.videoTrack.pipe(this.backgroundProcessor).pipe(this.localTracks.videoTrack.processorDestination);
        return this.backgroundProcessor;
    }

    async setBackgroundColor() {
        if (!this.isVirtualBackgroundEnabled && this.localTracks.videoTrack) {
            // document.getElementById("loading").hidden = false;
            let processor = await this.getBackgroundProcessorInstance();
            try {
                processor.setOptions({type: 'color', color: this.backgroundColor});
                await processor.enable();
            } finally {
                // document.getElementById("loading").hidden = true;
            }
            this.isVirtualBackgroundEnabled = true;
        }
    }

    async connectDenoiser() {
        // Create a VirtualBackgroundProcessor instance
        if (!this.isDenoiserEnabled && this.localTracks.audioTrack) {
            this.denoiserProcessor = this.denoiser.createProcessor();
            // Inject the extension to the audio processing pipeline
            this.localTracks.audioTrack.pipe(this.denoiserProcessor).pipe(this.localTracks.audioTrack.processorDestination);
            await this.denoiserProcessor.enable();
            console.log("Denoiser enabled!")
            this.isDenoiserEnabled = true;
            // Optional, listen the processor`s overlaod callback to catch overload message
            this.denoiserProcessor.on('overload', this.handleDenoiserOverload(5000))
        }
    }

    async handleDenoiserOverload(elapsedTime) {
        console.log("overload!!!", elapsedTime);
        // fallback or disable
        await this.denoiserProcessor.setMode("STATIONARY_NS");
        // await this.denoiserProcessor.disable();
    }

    async shareScreen() {
        if (!this.isSharingEnabled) {
            // Leave Channel
            this.leaveChannel();
            // Re-Join a channel.
            await this.agoraEngine.join(this.config.appId, this.config.channel, this.config.token, this.config.uid);
            // Create a local audio track from the audio sampled by a microphone.
            this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({encoderConfig: setSoundProfile(this.role)});
            // Create a local screen track for screen sharing.
            let screenTrack = await AgoraRTC.createScreenVideoTrack({
                encoderConfig: "720p", 
                selfBrowserSurface: "exclude"
            }, "enable");
            if (screenTrack instanceof Array) {
                this.localTracks.screenVideoTrack = screenTrack[0];
                this.localTracks.screenAudioTrack = screenTrack[1];
            } else {
                this.localTracks.screenVideoTrack = screenTrack;
            }
            // Set Local Player Container
            const localPlayerContainer = document.getElementById(this.localTracks.playerId);
            // play local video track
            this.localTracks.screenVideoTrack.play(localPlayerContainer);
            //bind "track-ended" event, and when screensharing is stopped, there is an alert to notify the end user.
            this.localTracks.screenVideoTrack.on("track-ended", () => {
                console.log('track ended')
                this.endCall();
            });
            // publish local tracks to channel
            if (this.localTracks.screenAudioTrack == null) {
                await this.agoraEngine.publish([this.localTracks.screenVideoTrack, this.localTracks.audioTrack]);
            } else {
                await this.agoraEngine.publish([this.localTracks.screenVideoTrack, this.localTracks.audioTrack, this.localTracks.screenAudioTrack]);
            }
            // Toggle buttons
            document.getElementById('castScreen').style.display = 'none';
            this.joinTarget.hidden = true;
            this.leaveTarget.hidden = false;
            // Update the screen sharing state.
            this.isSharingEnabled = true;
            console.log('screen share success!')
        }
    }

    videoEnable() {
        if (this.localTracks.videoTrack) {
            if (!this.isMuteVideo) {
                // Mute the local video.
                this.localTracks.videoTrack.setEnabled(false);
                // Toggle the Camera button
                this.cameraOnTarget.hidden = true;
                this.cameraOffTarget.hidden = false;
                this.isMuteVideo = true;
            } else {
                // Unmute the local video.
                this.localTracks.videoTrack.setEnabled(true);
                // Toggle the Camera button
                this.cameraOffTarget.hidden = true;
                this.cameraOnTarget.hidden = false;
                this.isMuteVideo = false;
            }
        }
    }

    audioEnable() {
        if (this.localTracks.audioTrack) {
            if(!this.isMuteAudio) {
                // Mute the local audio.
                this.localTracks.audioTrack.setEnabled(false);
                // Toggle the Mic button
                this.micOnTarget.hidden = true;
                this.micOffTarget.hidden = false;
                this.isMuteAudio = true;
            } else {
                // Unmute the local audio.
                this.localTracks.audioTrack.setEnabled(true);
                // Toggle the Mic button
                this.micOffTarget.hidden = true;
                this.micOnTarget.hidden = false;
                this.isMuteAudio = false;
            }
        }
    }

    notifyMicChange() {
        AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
            // When plugging in a device, switch to a device that is newly plugged in.
            if (changedDevice.state === "ACTIVE") {
                this.localTracks.audioTrack.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
            } else if (changedDevice.device.label === this.localTracks.audioTrack.getTrackLabel()) {
                const oldMicrophones = await AgoraRTC.getMicrophones();
                oldMicrophones[0] && this.localTracks.audioTrack.setDevice(oldMicrophones[0].deviceId);
            }
        }
    }

    notifyCameraChange() {
        AgoraRTC.onCameraChanged = async (changedDevice) => {
            // When plugging in a device, switch to a device that is newly plugged in.
            if (changedDevice.state === "ACTIVE") {
                this.localTracks.videoTrack.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
            } else if (changedDevice.device.label === this.localTracks.videoTrack.getTrackLabel()) {
                const oldCameras = await AgoraRTC.getCameras();
                oldCameras[0] && this.localTracks.videoTrack.setDevice(oldCameras[0].deviceId);
            }
        }
    }

    async leaveChannel() {
        // Disable Virtual Background
        if (this.isVirtualBackgroundEnabled) {
            this.backgroundProcessor.disable();
            this.backgroundProcessor = null;
            this.isVirtualBackgroundEnabled = false;
        }
        // Disable AI Denoiser
        if (this.isDenoiserEnabled) {
            this.denoiserProcessor.disable();
            this.denoiserProcessor = null;
            this.isDenoiserEnabled = false;
        }
        // Setback to default states
        this.isSharingEnabled = false;
        this.isMuteVideo = false;
        this.isMuteAudio = false;
        // Destroy the local audio and video tracks.
        for (const trackName in this.localTracks) {
            if (trackName === 'playerId') { continue; }
            var track = this.localTracks[trackName];
            if (track) {
                track.stop();
                track.close();
                this.localTracks[trackName] = null;
            }
        }
        // Leave the channel
        await this.agoraEngine.leave();
    }
    
    endCall() {
        this.leaveChannel();
        if (this.role == "teacher") {
            // Refresh the player and bottom menu for reuse
            this.refreshPage();
        } else {
            window.location.reload();
        }
        
    }

    async refreshPage() {
        const response = await get(this.urlValue, { 
            responseKind: "turbo-stream"
        })
        if (response.ok) {
            console.log("You left the channel");
        }
    }

    disconnect() {
        this.leaveChannel();
    }
}

function setVideoProfile(role) {
    if (role == "teacher") {
        return "720p_2"
    }
    if (role == "student") {
        return "360p_8"
    }
}

function setSoundProfile(role) {
    if (role == "teacher") {
        return "high_quality_stereo"
    }
    if (role == "student") {
        return "standard_stereo"
    }
}

function isObjectEmpty(objectName) {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
}