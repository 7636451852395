import { Controller } from "@hotwired/stimulus"
import Counter from "@hedgineering/time-counter"

// Connects to data-controller="time-counter"
export default class extends Controller {
    static targets = ["button", "display", "time"]

    startCount() {
        // Create Counter instance
        const counter = new Counter();
        // Toggle Button to Time
        this.buttonTarget.hidden = true;
        this.displayTarget.hidden = false;
        // Display Military Time
        this.timeTarget.innerText = counter.getMilitaryTime();
        // Count Start by seconds
        setInterval(() => {
            counter.increment('m');
            this.timeTarget.innerText = counter.getMilitaryTime()
        }, 60000);
    }
}