import { Controller } from "@hotwired/stimulus"
import noUiSlider from 'nouislider'

export default class extends Controller {
	static targets = ["slider"]

	connect() {
		if (this.hasSliderTarget) {
			noUiSlider.create(this.sliderTarget, {
            	start: [20, 80],
            	snap: false,
            	connect: true,
            	range: {
                	min: [0],
                	max: [100],
            	},
        	});
		} else { return }
	}
}