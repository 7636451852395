import { Controller } from "@hotwired/stimulus"
import requestSubmit from 'form-request-submit-polyfill'

export default class extends Controller {
	static targets = ["form", "select", "input"]

	connect() {
		if (!this.hasFormTarget) return
	}

	onChange() {
		const formElement = this.formTarget
		formElement.requestSubmit();
	}

	onInput() {
		const formElement = this.formTarget
		formElement.requestSubmit();
	}

}