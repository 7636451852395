import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["select", "input", "postcode"]

	changed(event) {
		event.preventDefault()
		if (event.target.selectedOptions[0].value == 6) {
			this.inputTarget.classList.remove('hidden-div')
			this.postcodeTarget.classList.add('hidden-div')
		} else {
			this.inputTarget.classList.add('hidden-div')
			this.postcodeTarget.classList.remove('hidden-div')
		}
	}
}