import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
	static targets = ["checkboxAll", "checkbox"]
	static values = {
		payRun: Number
	}
	selectedIds = null

	clicked(event) {
		if (event.target.value === "all") {
			this.checkboxTargets.forEach(checkbox => {
				checkbox.checked = true
			})
		} else {
			this.checkboxAllTarget.checked = false
		}
	}

	newPayment(event) {
		event.preventDefault()
		let data = new FormData()
		if (this.checkboxAllTarget.checked) {
			data.set("all", true)
		} else {
			this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
			this.selectedIds.forEach(id => data.append("ids[]", id))
		}
		const response = post(this.newPaymentUrl, { body: data, responseKind: "turbo-stream" })
	}

	sendEmail(event) {
		event.preventDefault()
		let data = new FormData()
		if (this.checkboxAllTarget.checked) {
			data.set("all", true)
		} else {
			this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
			this.selectedIds.forEach(id => data.append("ids[]", id))
		}
		const response = post(this.sendEmailUrl, { body: data, responseKind: "turbo-stream" })
	}

	get newPaymentUrl() {
		return "/admin/posted/pay_runs/"+ this.payRunValue +"/bulk_operations/payment_interfaces"
	}

	get sendEmailUrl() {
		return "/admin/posted/pay_runs/"+ this.payRunValue +"/bulk_operations/email_interfaces"
	}
}