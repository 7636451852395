import { Controller } from "@hotwired/stimulus"
import { Datepicker } from 'vanillajs-datepicker';

// Connects to data-controller="date-picker"
export default class extends Controller {
	static targets = ["input"]
	static values = { 
		options: { type: Object, default: { buttonClass: 'btn', clearButton: true, format: 'yyyy-mm-dd', orientation: 'bottom auto' } }
	}

  	dateClicked() {
  		const element = this.inputTarget
		this.datepicker = new Datepicker(element, this.optionsValue)
  	}

  	get defaultOptions () {
    	return {
    		// options...
        	buttonClass: 'btn',
			clearButton: true,
			format: 'yyyy-mm-dd',
			orientation: 'bottom auto'
    	}
  	}

}