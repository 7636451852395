import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller= "select-link-to"
export default class extends Controller {
    static targets = ["select"]

    changed(event) {
    	if (event.target.selectedOptions[0].value) {
            get(this.url, { 
                responseKind: "turbo-stream" 
            })
        } else {
            event.preventDefault();
        }
    }

    get url() {
        return this.selectTarget.selectedOptions[0].getAttribute('url')
    }
}