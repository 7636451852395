import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="i--inventory"
export default class extends Controller {
    static classes = ['urgent', 'safe']
    static values = {
        teacher: Number
    }

    connect() {
        this.update()
    }

    update() {
        let count = this.teacherValue
        let message = ""

        if (4 <= count && count <= 15) {
            message = `Hurry! Only ${count} class rooms left!`
            this.element.classList.add(...this.urgentClasses)
        } else if (2 <= count && count <= 3) {
            message = `Book now! There is only ${count} class rooms left!`
            this.element.classList.add(...this.urgentClasses)
        } else if (count == 1) {
            message = `Book now! There is only ${count} class room left!`
            this.element.classList.add(...this.urgentClasses)
        } else if (count == 0) { 
            message = ""
            this.element.classList.add(this.safeClass)
        } else {
            message = `There are ${count} class rooms available`
            this.element.classList.add(this.safeClass)
        }
        this.element.textContent = message
    }
}