import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ['input', 'counter']
	static values = { limit: Number }

	initialize () {
		this.update = this.update.bind(this)
	}

	connect () {
		this.inputTarget.addEventListener('input', this.update)
		this.update()
	}

	disconnect () {
		this.inputTarget.removeEventListener('input', this.update)
	}

	update () {
		let count = this.count
		let message = ""
		
		if (count == 1) {
			message = "1 character"
		} else {
			message = `${count} characters`
		}

		this.counterTarget.textContent = message

		if (count > this.limitValue) {
			this.counterTarget.classList.add("text-danger")
		} else {
			this.counterTarget.classList.remove("text-danger")
		}
	}

	get count () {
		return this.inputTarget.value.length
	}
}