import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["pointsInput", "pointsOutput"]
	static classes = ["overLimit"]
	static values = { portion: Number, spending: Number }

	connect() {
		this.rewardRatio();
	}

	rewardRatio() {
		let spendingAmount = this.spendingValue;
		let pointsEarned = this.pointsInputTarget.value;
		let portion = pointsEarned / spendingAmount

		this.pointsOutputTarget.textContent = `You are giving ${pointsEarned}% discounts for thier future purchase.`;

		if ( portion > this.portionValue ) {
			this.pointsOutputTarget.classList.add(this.overLimitClass)
		} else {
			this.pointsOutputTarget.classList.remove(this.overLimitClass)
		}
	}
}