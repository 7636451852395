import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-select-all"
export default class extends Controller {
	static targets = ["checkboxAll", "checkbox"]

	clicked(event) {
		if (event.target.value === "all") {
			this.checkboxTargets.forEach(checkbox => {
				checkbox.checked = true
			})
		} else {
			this.checkboxAllTarget.checked = false
		}
	}
}
