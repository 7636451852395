import { Controller } from "@hotwired/stimulus"
import { Splide } from '@splidejs/splide'

export default class extends Controller {
	static targets = ["carousel"]
	static values = { type: String }

	initialize() {
        this.carouselId = Math.random().toString(36)
		this.element.setAttribute("id", this.carouselId)
    }

	connect() {
		if (!this.hasCarouselTarget) return
		switch (this.typeValue) 
			{
				case 'full': 
					this.carouselTargets.forEach(carousel => new Splide(carousel, this.fullOptions).mount());
					break;
				case 'single': 
					this.carouselTargets.forEach(carousel => new Splide(carousel, this.singleOptions).mount());
					break;
				case 'multiple': 
					this.carouselTargets.forEach(carousel => new Splide(carousel, this.multipleOptions).mount());
					break;
				case 'small': 
					this.carouselTargets.forEach(carousel => new Splide(carousel, this.smallOptions).mount());
					break;
				case 'slider': 
					this.carouselTargets.forEach(carousel => new Splide(carousel, this.sliderOptions).mount());
					break;
				case 'story': 
					this.carouselTargets.forEach(carousel => new Splide(carousel, this.storyOptions).mount());
					break;
			}
	}

	get fullOptions() {
		return {
			perPage: 1,
        	rewind: true,
        	type: "loop",
        	gap: 0,
        	arrows: false,
        	pagination: false,
		}
	}

	get singleOptions() {
		return {
			perPage: 3,
        	rewind: true,
        	type: "loop",
        	gap: 16,
        	padding: 16,
        	arrows: false,
        	pagination: false,
        	breakpoints: {
            	768: {
               		perPage: 1
            	},
            	991: {
                	perPage: 2
            	}
        	}
		}
	}

	get multipleOptions() {
		return {
			perPage: 4,
        	rewind: true,
        	type: "loop",
        	gap: 16,
        	padding: 16,
        	arrows: false,
        	pagination: false,
        	breakpoints: {
            	768: {
                	perPage: 2
            	},
            	991: {
                	perPage: 3
            	}
        	}
		}
	}

	get smallOptions() {
		return {
			perPage: 9,
        	rewind: false,
        	type: "loop",
        	gap: 16,
        	padding: 16,
        	arrows: false,
        	pagination: false,
        	breakpoints: {
            	768: {
                	perPage: 5
            	},
            	991: {
                	perPage: 7
            	}
        	}
		}
	}

	get sliderOptions() {
		return {
			perPage: 1,
        	rewind: false,
        	type: "loop",
        	gap: 16,
        	padding: 16,
        	arrows: false,
        	pagination: true
		}
	}

	get storyOptions() {
		return {
			perPage: 16,
        	rewind: false,
        	type: "slide",
        	gap: 16,
        	padding: 16,
        	arrows: false,
        	pagination: false,
        	breakpoints: {
            	500: {
                	perPage: 4
            	},
            	768: {
                	perPage: 7
            	},
            	1200: {
                	perPage: 11
            	}
        	}
		}
	}

}