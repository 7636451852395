import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="eyepwd"
export default class extends Controller {
    static targets = ["field", "icon"]

    initialize() {
		this.toggle = this.toggle.bind(this)
	}

    connect() {
        if (this.hasFieldTarget) {
            this.iconTarget.addEventListener('click', this.toggle);
        }
    }

    toggle(event) {
        event.preventDefault()
        if (this.fieldTarget.type == 'password') {
            this.fieldTarget.setAttribute('type', 'text');
            this.iconTarget.classList.remove('bi-eye-slash');
            this.iconTarget.classList.add('bi-eye');
        } else {
            this.iconTarget.classList.remove('bi-eye');
            this.iconTarget.classList.add('bi-eye-slash');
            this.fieldTarget.setAttribute('type', 'password');
        }
    }
}