import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["select", "choice", "long"]

	connect() {
		this.selected()
	}

	selected() {
		this.hideFields()
		switch (this.selectTarget.value) {
			case 'single_choice':
				this.choiceTarget.classList.remove('hidden-div')
				break;
			case 'multiple_choice':
				this.choiceTarget.classList.remove('hidden-div')
				break;
			case 'long_answer':
				this.longTarget.classList.remove('hidden-div')
				break;
		}
	}

	hideFields() {
		this.choiceTarget.classList.add('hidden-div')
		this.longTarget.classList.add('hidden-div')
	}
}