import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"

// Connects to data-controller="tags"
export default class extends Controller {
	static values = { options: Array }

	connect() {
		new Tagify(this.element, {
			whitelist: this.optionsValue,
			dropdown: {
				classname: "color-blue",
				maxItems: 5,
				closeOnSelect: false,
				highlightFirst: true
			}
		})
	}
}
