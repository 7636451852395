import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['addItem', 'template']
    static values = {
        wrapperSelector: String, 
        index: String
    }

    initialize () {
        this.wrapperSelector = this.wrapperSelectorValue || '.nested-form-wrapper'
    }

    add (e) {
        e.preventDefault()
        const content = this.templateTarget.innerHTML.replace(new RegExp(this.indexValue, 'g'), new Date().valueOf())
        this.addItemTarget.insertAdjacentHTML('beforebegin', content)
    }

    remove (e) {
        e.preventDefault()
        const wrapper = e.target.closest(this.wrapperSelector)
        if (wrapper.dataset.newRecord === 'true') {
            wrapper.remove()
        } else {
            wrapper.style.display = 'none'
            wrapper.querySelector("input[name*='_destroy']").value = 1
        }
    }
}