import { Controller } from "@hotwired/stimulus"
import { Datepicker } from 'vanillajs-datepicker';

// Connects to data-controller= "date-picker-inline"
export default class extends Controller {
	static values = {
        minDate: String,
        maxDate: String
    }

  	connect () {
		this.datepicker = new Datepicker(this.element, this.defaultOptions)
		this.element.addEventListener('changeDate', this.getDate)
  	}

	disconnect () {
		this.element.removeEventListener('changeDate', this.getDate)
		this.datepicker.destroy()
	}

	getDate (event) {
		event.preventDefault();
		const date = this.datepicker.getDate()
		const element = document.getElementById('date_picker_form')
		element.setAttribute('data-date', date)
		element.value = element.dataset.date
	}

  	get defaultOptions () {
    	return {
    		// options...
        	buttonClass: 'btn',
			format: 'yyyy-mm-dd',
            minDate: this.minDateValue,
            maxDate: this.maxDateValue,
            maxView: 0,
            weekStart: 1
    	}
  	}

}