import { Controller } from "@hotwired/stimulus"
import { post, destroy } from '@rails/request.js'

// Connects to data-controller="admin--voc-bulk-ops"
export default class extends Controller {
	static targets = ["checkboxAll", "checkbox"]
	selectedIds = null

	clicked(event) {
		if (event.target.value === "all") {
			this.checkboxTargets.forEach(checkbox => {
				checkbox.checked = true
			})
		} else {
			this.checkboxAllTarget.checked = false
		}
	}

	closing(event) {
		event.preventDefault()
		let data = new FormData()
		if (this.checkboxAllTarget.checked) {
			data.set("all", true)
		} else {
			this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
			this.selectedIds.forEach(id => data.append("ids[]", id))
		}
		const response = post(this.closingUrl, { body: data, responseKind: "turbo-stream" })
	}

	remove(event) {
		event.preventDefault()
		let data = new FormData()
		if (this.checkboxAllTarget.checked) {
			data.set("all", true)
		} else {
			this.selectedIds = this.checkboxTargets
				.filter(checkbox => checkbox.checked)
				.map(checkbox => checkbox.value)
			this.selectedIds.forEach(id => data.append("ids[]", id))
		}
		const response = destroy(this.removeUrl, { body: data, responseKind: "turbo-stream" })
	}

	get closingUrl() {
		return "/admin/vou_bulk_ops/closings"
	}

	get removeUrl() {
		return "/admin/vou_bulk_ops/closed_removals"
	}
}