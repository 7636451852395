import { Controller } from "@hotwired/stimulus";
import Coloris from "@melloware/coloris";

// Connects to data-controller="colors"
export default class extends Controller {
    static targets = ["input"]
    static values = {
        parent: { type: String, default: "empty" }
    }

    initialize() {
        Coloris.init();
    }
    
    connect() {
        Coloris({ 
            parent: this.parentValue == "empty" ? null : this.parentValue,
            el: "#coloris",
            themeMode: 'dark',
            alpha: false,
            swatches: getSwathces(),
            defaultColor: this.currentValue,
        })
    }

    disconnect() {
        Coloris.close();
    }
}

function getSwathces() {
    return [
        '#156760',
        '#1D8D84',
        '#24B3A8',
        '#30D5C8',
        '#56DDD2',
        '#7CE4DC',
        '#BEAFC2',
        '#9CCD83',
        '#7DD197',
        '#6AB8EE',
        '#FEDE00',
        '#00D2F5',
        '#A16AE8',
        '#FFA0B7',
        '#F2879F',
        '#D76F88',
        '#D0C06E',
        '#93C0FF',
        '#FFC5D0',
        '#FFFF00',
        '#603F8B',
        '#F52EC0'
    ]
}