import { Controller } from "@hotwired/stimulus"
import { TemplateInstance } from '@github/template-parts'

export default class extends Controller {
    static targets = ["template"]
    static values = { 
        index: Number, 
        key: String 
    }

    templateTargetConnected() {
        let target = this.templateTarget
        const templateInstance = new TemplateInstance(target, {
            [this.keyValue]: this.indexValue
        })

        target.content.replaceChildren(templateInstance)

        this.indexValue++

    }

}