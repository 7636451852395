import { Controller } from "@hotwired/stimulus"
import { destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = ["link"]
  static values = { model: String }

  async clicked () {
    const response = await destroy(this.deleteURL, { responseKind: "json" })
    if (response.ok) {
      Turbo.visit('/')
    }
  }

  get deleteURL () {
    return 'http://localhost:3000/' + this.modelValue + '/logout'
  }
}