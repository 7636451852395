import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ['enabler', 'follower']

	initialize () {
    	this.toggle = this.toggle.bind(this)
	}

 	connect () {
		if (!this.hasEnablerTarget) return
		this.enablerTarget.addEventListener('change', this.toggle)
	}

	disconnect () {
		if (!this.hasEnablerTarget) return
		this.enablerTarget.removeEventListener('change', this.toggle)
	}

	toggle (e) {
		e.preventDefault()
		this.followerTargets.forEach(follower => {
			follower.disabled = !e.target.checked;
			follower.checked = e.target.checked;
		});
	}

}