import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-top"
export default class extends Controller {

	connect() {
		this.element[this.identifier] = this
		this.scrollToTop()
	}

	scrollToTop() {
		this.element.scrollIntoView()
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}
}