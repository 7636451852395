import { Controller } from "@hotwired/stimulus"
import Uppy, { debugLogger } from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Webcam from '@uppy/webcam'
import isMobile from 'is-mobile'
import DropTarget from '@uppy/drop-target'
import Compressor from '@uppy/compressor'
import ActiveStorageUpload from '../src/uppy-activestorage-upload'
import { patch } from '@rails/request.js'

export default class extends Controller {
    static targets = ["button"]
    static values = { 
        updateUrl: String,
        fieldName: String,
        type: String,
        trigger: { type: String, default: 'UppyModalOpenerBtn' },
        dashboardInline: Boolean,
        multi: Boolean,
        inForm: Boolean,
        preview: Boolean
    }

    initialize() {
        this.buttonTarget.classList.add(this.triggerValue);
    }

    connect() {
        this.uppyInit()
        switch (this.typeValue) {
            case 'avatar':
                this.avatarOptions();
                break;
            case 'coverImage':
                this.coverImageOptions();
                break;
            default:
                this.uppySetOptions();
        } 
    }

    disconnect() {
        if (this.uppy) {
            this.uppy.close()
        }
    }

    uppyInit() {

        const uppy = new Uppy({
            logger: debugLogger,
        })

        uppy.use(Dashboard, {
            trigger: `.${this.triggerValue}`,
            target: this.dashboardInlineValue ? '.DashboardContainer' : 'body',
            inline: this.dashboardInlineValue,
            height: this.dashboardInlineValue ? 350 : 470,
            closeAfterFinish: this.dashboardInlineValue ? false : true,
            showProgressDetails: true,
            metaFields: [
                { id: 'name', name: 'Name', placeholder: 'file name' },
            ],
        })

        uppy.on('complete', result => {
            result.successful.forEach(file => {
                this.inFormValue ? this.appendUploadedFile(file) : this.updatePath(file);
                if (this.previewValue) {
                    this.setPreview(file)
                }
            })
        })

        this.uppy = uppy
    }

    avatarOptions () {
        const restrictions = {
            maxFileSize: 5000000,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
        }
        this.uppy.setOptions({
            autoProceed: true,
            allowMultipleUploadBatches: false,
            restrictions: restrictions,
        })
        this.uppy.getPlugin('Dashboard').setOptions({
            note: 'Image only, up to 5 MB',
        })
        this.uppy.use(ActiveStorageUpload, { directUploadUrl: this.postURL() })
        this.uppy.use(Webcam, { 
            target: Dashboard,
            countdown: 3,
            modes: ['picture'],
            mirror: true,
            mobileNativeCamera: isMobile({ tablet: true }),
        })
        this.uppy.use(DropTarget, { target: document.body })
        this.uppy.use(Compressor)
    }

    coverImageOptions () {
        const restrictions = {
            maxFileSize: 5000000,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
        }
        this.uppy.setOptions({
            autoProceed: true,
            allowMultipleUploadBatches: false,
            restrictions: restrictions,
        })
        this.uppy.getPlugin('Dashboard').setOptions({
            note: 'Image only, No PDF, up to 5 MB',
        })
        this.uppy.use(ActiveStorageUpload, { directUploadUrl: this.postURL() })
        this.uppy.use(DropTarget, { target: document.body })
        this.uppy.use(Compressor)
    }

    uppySetOptions() {
        const restrictions = {
            maxFileSize: 5000000,
            maxNumberOfFiles: this.multiValue ? null : 1,
            minNumberOfFiles: 1,
        }
        this.uppy.setOptions({
            autoProceed: true,
            allowMultipleUploadBatches: false,
            restrictions: restrictions,
        })
        this.uppy.getPlugin('Dashboard').setOptions({
            note: 'Image & Document Only, up to 5 MB per file.',
        })
        this.uppy.use(ActiveStorageUpload, { directUploadUrl: this.postURL() })
        this.uppy.use(DropTarget, { target: document.body })
    }

    postURL() {
        return '/rails/active_storage/direct_uploads'
    }

    async updatePath(file) {
        let formData = new FormData();
        formData.append(this.fieldNameValue, file.response.signed_id)
        const response = await patch(this.updateUrlValue, { 
            body: formData,
            responseKind: "turbo-stream" 
        })
        if (response.ok) {
            console.log("File uploads success!")
        }
    }

    appendUploadedFile(file) {
        this.hiddenInput = document.createElement("input")
        this.hiddenInput.type = "hidden"
        this.hiddenInput.name = this.fieldNameValue
        this.hiddenInput.value = file.response.signed_id
        this.buttonTarget.parentNode.insertBefore(this.hiddenInput, this.buttonTarget.nextSibling)
    }

    setPreview(file) {
        const preview = document.querySelector('[data-behavior="uppy-preview"]')
        if (preview) {
            let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
            preview.src = src
        }
    }
}

