import { Controller } from "@hotwired/stimulus"
import GLightbox from 'glightbox'

export default class extends Controller {
	static targets = ['gallery']

	connect() {
		if (this.hasGalleryTarget) {
			const lightbox = GLightbox(this.defaultOptions);
		}
	}

	get defaultOptions() {
		return {
			touchNavigation: true,
        	loop: true,
        	autoplayVideos: true,
		}
	}
}