import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ['radioButton', 'input1', 'input2']
	static classes = ['toggle']

	initialize() {
		this.toggle = this.toggle.bind(this)
	}

	connect() {
		this.value1 = this.radioButtonTargets[0].value
		this.radioButtonTargets.forEach((element) =>{
			element.addEventListener('change', this.toggle)
		})
	}

	disconnect() {
		this.radioButtonTargets.forEach((element) =>{
			element.removeEventListener('change', this.toggle)
		})
	}

	toggle(event) {
		event.preventDefault()
		this.radioButtonTargets.forEach((element) =>{
			if (element.checked) {
				this.value2 = element.value
			}
		});
		if (this.value1 !== this.value2) {
			this.input1Targets.forEach((element) =>{
				element.classList.add(this.toggleClass)
			});
			this.input2Targets.forEach((element) =>{
				element.classList.remove(this.toggleClass)
			});
		} else {
			this.input1Targets.forEach((element) => {
				element.classList.remove(this.toggleClass)
			});
			this.input2Targets.forEach((element) => {
				element.classList.add(this.toggleClass)
			});
		}
	}
}