import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = ["radio"]
	static values = {
		showUrl: String 
	}

	initialize() {
		if (!this.hasRadioTarget) return
		this.radioTarget.setAttribute("data-action", "click->radio-link-to#clicked");
	}

	clicked() {
		const response = get(this.showUrlValue, { 
			responseKind: "turbo-stream" 
		})

	}
}