import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay, Scrollbar } from 'swiper/modules'

// Connects to data-controller="swiper"
export default class extends Controller {
	static targets = ["container"]
    static values = {
        spaceBetween: Number,
        slidesPerView: { type: Number, default: 1 },
        loop: { type: Boolean, default: true },
        autoplay: { type: Object, default: { 
            delay: 2000, disableOnInteraction: false, pauseOnMouseEnter: false, } 
        },
        freeMode: Boolean,
        scrollbar: Boolean,
        navigation: Boolean,
        pagination: Object,
        breakpoints: Object
    }

	connect() {
		if (this.hasContainerTarget) {
            const swiper = new Swiper(this.containerTarget, {
                // configure Swiper to use modules
                modules: [Navigation, Pagination, Autoplay, Scrollbar],
                ...this.options
            })
        }
	}

    get options() {
        return {
            spaceBetween: this.spaceBetweenValue,
            slidesPerView: this.slidesPerViewValue,
            loop: this.loopValue,
            autoplay: this.autoplayValue,
            freeMode: this.freeModeValue,
            navigation: this.navigationValue ? { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" } : {},
            scrollbar: this.scrollbarValue ? { el: ".swiper-scrollbar" } : {},
            pagination: this.paginationValue,
            breakpoints: this.breakpointsValue
        }
    }
}