import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["toggler", "sidebar"]

	connect() {
		if (this.hasTogglerTarget) {
			let sidebar = this.sidebarTarget
			this.togglerTarget.addEventListener("click", function (e) {
            	e.preventDefault();
            	sidebar.classList.toggle("shrink");
            	sidebar.classList.toggle("show");
        	});
		} else {
			return
		}
	}
}