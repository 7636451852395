import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toast"]

    connect() {
        if (!this.hasToastTarget) return
        toastbox(this.toastTarget, 5000, flashTypeClass(this.flashType))
    }

    get flashType() {
        return this.toastTarget.getAttribute('data-flash-type')
    }

}

function toastbox(target, time, flash) {
    let a = target;
    let b = flash;
    a.classList.remove("show")
    setTimeout(() => {
        a.classList.add("show")
        a.classList.add(b)
    }, 100);
    if (time) {
        time = time + 100;
        setTimeout(() => {
            a.classList.remove("show")
        }, time);
    }
}

function flashTypeClass(type) {
    if (!type) return "bg-info"
    switch (type) 
            {
                case 'success': 
                    return "bg-success"
                    break;
                case 'error': 
                    return "bg-danger"
                    break;
                case 'alert': 
                    return "bg-warning"
                    break;
                case 'notice': 
                    return "bg-info"
                    break;
            }
}