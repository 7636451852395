import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js';

export default class extends Controller {
	static targets = ["checkbox"]
	static values = {
		updateUrl: String, 
		model: String, 
		param: String
	}

	async toggled(event) {
		let formData = new FormData();
		formData.append(this.getParams, this.checkboxTarget.checked);
		const response = await patch(this.updateUrlValue, { body: formData })
		if (response.ok) {
			let successMessage = this.paramValue + ' is successfully updated!'
			console.log(successMessage)
		} else {
        	event.target.checked = !event.target.checked;
        	let errorMessage = this.paramValue + ' was failed to be updated.'
        	console.log(errorMessage)
      	}
	}

	get getParams() {
		return this.modelValue + "[" + this.paramValue + "]"
	}
}