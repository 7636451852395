import { Controller } from "@hotwired/stimulus"
import { init } from 'ityped'

// Connects to data-controller="ityped"
export default class extends Controller {
    static targets = ["span"]
    static values = {
        text: String,
        speed: { type: Number, default: 200 },
        backSpeed: { type: Number, default: 50 }
    }

    connect() {
        if (this.hasSpanTarget) {
            const type = this.spanTarget;
            let strings = this.textValue;
            let split_strings = strings.split("&&");
            let typespeed = this.speedValue;;
            let typeBackSpeed = this.backSpeedValue;

            init(type, { 
                strings: split_strings,
                showCursor: true,
                typeSpeed: typespeed,
                backSpeed: typeBackSpeed 
            });
        }
    }
}