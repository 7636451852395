import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["select", "button"]
	static classes = ['primary']

	initialize () {
    	this.toggle = this.toggle.bind(this)
	}

 	connect () {
		if (!this.hasSelectTarget) return

		this.href = '/i/wallets/charges?price_id='
		this.selectTarget.addEventListener('change', this.toggle)
	}

	disconnect () {
		if (!this.hasSelectTarget) return
		this.selectTarget.removeEventListener('change', this.toggle)
	}

	toggle (e) {
		let value = e.target.value
		if (value) {
      		this.buttonTarget.disabled =false;
      		this.buttonTarget.classList.add(this.primaryClass);
      		this.buttonTarget.textContent = this.selectTarget.selectedOptions[0].textContent;
    	} else {
      		this.buttonTarget.disabled =true;
      		this.buttonTarget.classList.remove(this.primaryClass);
      		this.buttonTarget.textContent = 'PROCEED TO CHECKOUT'
    	}

	}
}