import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller= "modal"
export default class extends Controller {
	connect() {
		this.modal = new Modal(this.element, {
			keyword: false,
			backdrop: "static"
		})
		this.modal.show()
	}

	disconnect() {
		this.modal.hide()
	}
}