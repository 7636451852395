import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = ["input", "select", "setting", "field"]
	static values = {
        url: String,
        country: String
    }

	connect() {
		if (this.hasInputTarget) {
			this.inputTarget.setAttribute("data-action", "keyup->form-tel-input#keyup")
			let userTelephone = this.settingTarget.getAttribute("data-phone-number")
			this.phoneInput = intlTelInput(this.inputTarget, {
    			// any initialisation options go here
    			preferredCountries: ["US", "TH", "VN", "ID", "CN", "SG", "HK", "TW", "MY", "JP", "KR"],
    			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
    			formatOnDisplay: false,
    			separateDialCode: true
			});
			this.phoneInput.setNumber(userTelephone);
		}	
	}

	disconnect() {
		if (this.hasInputTarget) {
			const input = this.inputTarget;
			intlTelInput(input).destroy();
		}
	}

	keyup(event) {
		event.preventDefault();
		let phoneNumber = this.phoneInput.getNumber();
		this.fieldTarget.value = phoneNumber
	}

	async changed(event) {
		let params = new URLSearchParams()
		params.append(this.countryValue, event.target.selectedOptions[0].value)
		const response = await get(`${this.urlValue}?${params}`, { responseKind: "json" })
		if (response.ok) {
            const data = await response.json;
            this.settingTarget.setAttribute('data-country-code', data);
            let country = this.settingTarget.getAttribute('data-country-code');
            this.phoneInput.setNumber(setCountryCode(country));
        }
	}
}

function setCountryCode(country) {
	const regex = /^\d+$/;
    if (regex.test(country)) {
    	return "+" + country
    } else {
    	return "+1"
    }
}