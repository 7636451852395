import { Controller } from "@hotwired/stimulus"
import { DataTable } from 'simple-datatables'

export default class extends Controller {
	static targets = ["table", "wrapper"]
	static values = {
		searchBar: { type: Boolean, default: false },
		noSort: { type: Array, default: [] }
	}

	initialize() {
        this.tableId = Math.random().toString(36)
		this.element.setAttribute("id", this.tableId)
    }

	connect() {
		if (this.hasTableTarget) {
			const dataTable = new DataTable(this.tableTarget, { 
				searchable: this.searchBarValue,
				columns: [
					// Disable sorting on the first column
					{ select: this.noSortValue, sortable: false },
				],
			});

			const parentElement = document.getElementById(this.tableId);
			//function adjustTableColumns() {
			//	let columns = dataTable.columns();

			//	if (window.innerWidth < 600) {
			//		columns.hide([2, 3, 4, 6]);
			//		columns.show([0, 1, 5]);
			//	}
			//}

			//adjustTableColumns();

			//window.addEventListener("resize", adjustTableColumns);

			// Add Bootstrap styles to the elements in the header
			dataTable.on("datatable.init", function () {

				if (this.searchBarValue == true) {
					const input = parentElement.querySelector(".dataTable-input");
					input.classList.add("form-control", "form-control-lg");
				}

				const dataTableSelect = parentElement.querySelector(".dataTable-selector");
				dataTableSelect.classList.add("form-control", "form-control-lg");

				const dataTableContainer = parentElement.querySelector(".dataTable-container");
				dataTableContainer.classList.add("border-0");
			});

			// Fade in after init
			const preloadWrapper = this.wrapperTarget
    		dataTable.on("datatable.init", function (args) {
        		preloadWrapper.classList.add("opacity-10");
    		});

		}
	}

}