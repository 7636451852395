import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["entry", "value", "debit", "credit", "totalDebits", "totalCredits", "inputDebits", "inputCredits"]

	initialize() {
		this.totalDebitsTarget.innerText = "0.00"
		this.totalCreditsTarget.innerText = "0.00"
		this.inputDebitsTarget.value = 0.00
		this.inputCreditsTarget.value = 0.00
	}

	capture(e) {
		e.preventDefault();
		if (e.target.value > 0 ) {
			const debits = new Array();
			const credits = new Array();
			this.entryTargets.forEach((el, i) => {
				if (el.hidden == false) {
					if (this.debitTargets[i].checked)
						debits.push(parseFloat(this.valueTargets[i].value))
					else if (this.creditTargets[i].checked) {
						credits.push(parseFloat(this.valueTargets[i].value))
					}
				}
			})
			let total_debits = debits.reduce((a, b) => a + b, 0)
			let total_credits = credits.reduce((a, b) => a + b, 0)
			this.inputDebitsTarget.value = total_debits
			this.inputCreditsTarget.value = total_credits
			this.totalDebitsTarget.innerText = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(total_debits)
			this.totalCreditsTarget.innerText = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(total_credits)
		}
	}

	remove() {
		const debits = new Array();
		const credits = new Array();
		this.entryTargets.forEach((el, i) => {
			if (el.hidden == false) {
				if (this.debitTargets[i].checked)
					debits.push(parseFloat(this.valueTargets[i].value))
				else if (this.creditTargets[i].checked) {
					credits.push(parseFloat(this.valueTargets[i].value))
				}
			}
		})
		let total_debits = debits.reduce((a, b) => a + b, 0)
		let total_credits = credits.reduce((a, b) => a + b, 0)
		this.inputDebitsTarget.value = total_debits
		this.inputCreditsTarget.value = total_credits
		if (total_debits == 0) {
			this.totalDebitsTarget.innerText = "0.00"
		} else {
			this.totalDebitsTarget.innerText = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(total_debits)
		}
		if (total_credits == 0) {
			this.totalCreditsTarget.innerText = "0.00"
		} else {
			this.totalCreditsTarget.innerText = new Intl.NumberFormat({ style: 'currency', currency: 'SGD' }).format(total_credits)
		}
	}

}