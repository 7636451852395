import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["modal", "radioButton"];
	static values = { event: String };

	initialize() {
		document.addEventListener(this.eventValue, this.handleSelection.bind(this));
	}

 	toggled(event) {
		event.stopImmediatePropagation();

		const selectionEvent = new CustomEvent(this.eventValue, {
			detail: {
				name: event.target.name,
				value: event.target.value,
			},
		});
		document.dispatchEvent(selectionEvent);
		return false;
	}

	handleSelection(event) {
		if (event.detail.name === this.radioButtonTarget.name) {
			this.modalTarget.hidden = event.detail.value !== this.radioButtonTarget.value;
		}
	}
}