import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js';

export default class extends Controller {
	static targets = ["country", "province", "district", "subDistrict"]

	connect() {
		this.check_forms()
	}

	check_forms() {
		if (this.subDistrictTarget.getAttribute('data-district-id')) {
            this.countryTarget.value = this.subDistrictTarget.getAttribute('data-country-id')
            this.provinceTarget.value = this.subDistrictTarget.getAttribute('data-province-id')
            this.districtTarget.value = this.subDistrictTarget.getAttribute('data-district-id')
            this.country_changed()
        }
	}

    async country_changed(event) {
    	const response = await get(this.country_url, {responseKind: "json"})
    	if (response.ok) {
    		const data = await response.json
            this.provinceTarget.innerText = null
            this.districtTarget.innerText = null
            this.subDistrictTarget.innerText = null
            this.provinceTarget.appendChild(document.createElement('option'))
            for (let i = 0; i < data.length; i++) {
                let opt = document.createElement('option')
                opt.textContent = data[i][0]
                opt.value = data[i][1]
                opt.setAttribute('url', data[i][2]['url'])
                this.provinceTarget.appendChild(opt)
            }

    		if (this.subDistrictTarget.getAttribute('data-province-id')) {
    			this.provinceTarget.value = this.subDistrictTarget.getAttribute('data-province-id')
    			this.subDistrictTarget.removeAttribute('data-province-id')
                this.subDistrictTarget.innerText = null
    			this.province_changed()
    		}
    	}
    }

    async province_changed(event) {
    	const response = await get(this.province_url, { responseKind: "json" })
    	if (response.ok) {
    		const data = await response.json
            this.districtTarget.innerText = null
            this.subDistrictTarget.innerText = null
            this.districtTarget.appendChild(document.createElement('option'))
            for (let i = 0; i < data.length; i++) {
                let opt = document.createElement('option')
                opt.textContent = data[i][0]
                opt.value = data[i][1]
                opt.setAttribute('url', data[i][2]['url'])
                this.districtTarget.appendChild(opt)
            }

            if (this.subDistrictTarget.getAttribute('data-district-id')) {
                this.districtTarget.value = this.subDistrictTarget.getAttribute('data-district-id')
                this.subDistrictTarget.removeAttribute('data-district-id')
                this.subDistrictTarget.innerText = null
                this.district_changed()
            }
    		
    	}
    }

    async district_changed(event) {
    	const response = await get(this.district_url, { responseKind: "json" })
    	if (response.ok) {
    		const data = await response.json
            this.subDistrictTarget.innerText = null
            this.subDistrictTarget.appendChild(document.createElement('option'))
            for (let i = 0; i < data.length; i++) {
                let opt = document.createElement('option')
                opt.textContent = data[i][0]
                opt.value = data[i][1]
                this.subDistrictTarget.appendChild(opt)
            }

            if (this.subDistrictTarget.getAttribute('data-sub-district-id')) {
                this.subDistrictTarget.value = this.subDistrictTarget.getAttribute('data-sub-district-id')
                this.subDistrictTarget.removeAttribute('data-sub-district-id')
            }
    		
    	}
    }

    get country_url() {
        return this.countryTarget.selectedOptions[0].getAttribute('url')
    }

    get province_url() {
        return this.provinceTarget.selectedOptions[0].getAttribute('url')
    }

    get district_url() {
        return this.districtTarget.selectedOptions[0].getAttribute('url')
    }

}