import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="i--toggle-searchbox"
export default class extends Controller {

    openForm() {
        const search = document.getElementById("search")
        search.classList.add("show")
        search.querySelector(".form-control").focus();
    }

    closeForm() {
        const search = document.getElementById("search")
        search.classList.remove("show")
    }

    disconnect() {
        const search = document.getElementById("search")
        search.classList.remove("show")
    }

}